import CryptoJS from "crypto-js";

const AES_KEY = process.env.REACT_APP_AES_KEY 
const AES_ALGORITHMS = 'aes-128-ecb'; 

const encryptAES = (text, masterkey) => {
    try {
        const aesKey = CryptoJS.enc.Hex.parse(masterkey);

        const encrypted = CryptoJS.AES.encrypt(text, aesKey, {
            mode: CryptoJS.mode.ECB, 
            padding: CryptoJS.pad.Pkcs7,
        });

        return encrypted.toString(); 
    } catch (error) {
        console.error('Error during AES encryption:', error);
        throw new Error('Encryption failed');
    }
};

const decryptAES = (data, masterkey) => {
    try {
        const aesKey = CryptoJS.enc.Hex.parse(masterkey);

        const decrypted = CryptoJS.AES.decrypt(data, aesKey, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });

        return decrypted.toString(CryptoJS.enc.Utf8); 
    } catch (error) {
        console.error('Error during AES decryption:', error);
        throw new Error('Decryption failed');
    }
};

export const decryptRequest = (data) => {
    try {
        const result = decryptAES(data, AES_KEY);
        return JSON.parse(result);
    } catch (error) {
        console.error('Error in decryptRequest:', error);
        throw new Error('Decryption failed');
    }
};

export const encryptRequest = (resData) => {
    try {
        const result = encryptAES(JSON.stringify(resData), AES_KEY);
        return result;
    } catch (error) {
        console.error('Error in encryptRequest:', error);
        throw new Error('Encryption failed');
    }
};